<template>
    <el-button :loading="downloadLoading" type="primary" @click="handleDownload">导出 Excel</el-button>
</template>

<script>
    export default {
        name: 'ExportExcel',
        props: {
            tHeader: {
                required: true,
                type: Array
            },
            tValue: {
                required: true,
                type: Array
            },
            filename: {
                type: String,
                default: '导出数据'
            },
            apiUrl: {
                type: String,
                default: '导出Excel数据接口地址'
            },
            params: {
                type: Object,
                default: null
            },
        },
        data() {
            return {
                downloadLoading: false,
                autoWidth: true,
                bookType: 'xlsx'
            }
        },
        methods: {
            handleDownload() {
                console.log(this.params);
                console.log(this.apiUrl);
                // return;
                this.$api.post(this.apiUrl, this.$qs.stringify(this.params), res => {
                    console.log(res);
                    import('./Export2Excel').then(excel => {
                        const data = this.formatJson(this.tValue, res.data);
                        excel.export_json_to_excel({
                            header: this.tHeader,
                            data,
                            filename: this.filename,
                            autoWidth: this.autoWidth,
                            bookType: this.bookType
                        });
                        this.downloadLoading = false
                    })
                }, err => {
                    console.log(err);
                    this.$message.error(err.msg);
                });
                this.downloadLoading = true;

            },
            formatJson(filterVal, jsonData) {
                return jsonData.map(v => filterVal.map(j => {
                    return v[j]
                }))
            }
        }
    }
</script>
